import React from "react"
import MainLayout from "../components/MainLayout"
import PageHeading from "../components/PageHeadings"
import styled from "styled-components"
import axios from "axios"

const PageWrapper = styled.div`
    display: grid;
    align-content: flex-start;
    padding: 2rem 4%;
    form {
        display: grid;
    }
    h1 {
        padding: 0px;
        margin: 0px;
    }
    input {
        display: grid;
        border: none;
        background-color: rgba(204, 210, 198, .45);
        height: 25px;
        padding: .25rem .5rem;
        border-radius: .15rem;
        ::placeholder {
            color: rgba(0,0,0, .35);
        }
    }
    select {
        height: 35px;
        background-color: rgba(204, 210, 198, .45);
        border-radius: .15rem;
        border: none;
    }
    label {
        margin-top: .5rem;
        padding: .25rem 0rem;
        font-size: .9rem;
        font-weight: bold;
        color: #333;
    }
    button {
        display: grid;
        align-content: center;
        height: 35px;
        background: #606b55;
        border: none;
        padding: .5rem .5rem;
        margin-top: 1rem;
        border-radius: .15rem;
        color: rgba(204, 210, 198, .95);
        font-weight: bold;
        transition: ease-in-out 200ms;
        &:hover {
            display: grid;
            cursor: pointer;
            background: #505b44;
            color: white;
        }
    }
}`

const CORS_PROXY =
  "http://www.factorycat.com/cyberlynkProxy.php?path=registration&action=getModels&brand=KODIAK"
async function handleSubmit(e) {
  e.preventDefault()
  const response = await fetch(CORS_PROXY, {
    mode: `no-cors`,
  }).then(resp => {
    console.log(resp)
  })
}
export default function register() {
  return (
    <MainLayout>
      <PageHeading pageHeading="Register your Machine" />
      <PageWrapper>
        <form onSubmit={handleSubmit}>
          <label for="companyName">Company Name</label>
          <input placeholder="Enter a valid email" type="text" />
          <label>First Name</label>
          <input placeholder="Enter a valid email" type="text" />
          <label>Last Name</label>
          <input placeholder="Enter a valid email" type="text" />
          <label>Phone Number</label>
          <input placeholder="Enter a valid email" type="phone" />
          <label>Street Adress</label>
          <input placeholder="Enter a valid email" type="phone" />
          <label>City</label>
          <input placeholder="Enter a valid email" type="phone" />
          <label>State</label>
          <select>
            <option value="Choose State">Choose State</option>
            <option value="AL">AL</option>
            <option value="AK">AK</option>
            <option value="AR">AR</option>
            <option value="AZ">AZ</option>
            <option value="CA">CA</option>
            <option value="CO">CO</option>
            <option value="CT">CT</option>
            <option value="DC">DC</option>
            <option value="DE">DE</option>
            <option value="FL">FL</option>
            <option value="GA">GA</option>
            <option value="HI">HI</option>
            <option value="IA">IA</option>
            <option value="ID">ID</option>
            <option value="IL">IL</option>
            <option value="IN">IN</option>
            <option value="KS">KS</option>
            <option value="KY">KY</option>
            <option value="LA">LA</option>
            <option value="MA">MA</option>
            <option value="MD">MD</option>
            <option value="ME">ME</option>
            <option value="MI">MI</option>
            <option value="MN">MN</option>
            <option value="MO">MO</option>
            <option value="MS">MS</option>
            <option value="MT">MT</option>
            <option value="NC">NC</option>
            <option value="NE">NE</option>
            <option value="NH">NH</option>
            <option value="NJ">NJ</option>
            <option value="NM">NM</option>
            <option value="NV">NV</option>
            <option value="NY">NY</option>
            <option value="ND">ND</option>
            <option value="OH">OH</option>
            <option value="OK">OK</option>
            <option value="OR">OR</option>
            <option value="PA">PA</option>
            <option value="RI">RI</option>
            <option value="SC">SC</option>
            <option value="SD">SD</option>
            <option value="TN">TN</option>
            <option value="TX">TX</option>
            <option value="UT">UT</option>
            <option value="VT">VT</option>
            <option value="VA">VA</option>
            <option value="WA">WA</option>
            <option value="WI">WI</option>
            <option value="WV">WV</option>
            <option value="WY">WY</option>
          </select>
          <label>Email</label>
          <input placeholder="Enter a valid email" type="email" />
          <label>Serial Number</label>
          <input placeholder="Enter a valid email" type="email" />
          <label>Model</label>
          <input placeholder="Enter a valid email" type="email" />
          <button type="submit">Register</button>
        </form>
      </PageWrapper>
    </MainLayout>
  )
}
